import type { FC, MouseEvent } from 'react';
import React, { Fragment, memo, useContext, useState, useCallback, useRef, useEffect } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import type { IntlShape } from 'react-intl-next';
import { useIntl } from 'react-intl-next';
import find from 'lodash/find';
import uuid from 'uuid/v4';
import type { ApolloError } from 'apollo-client';
import memoizeOne from 'memoize-one';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	PAGE_TREE_EXPERIENCE,
	PAGE_TREE_DND_EXPERIENCE,
	ExperienceStart,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import {
	perfMarkEnd,
	getMark,
	PERFORMANCE_SUBJECT_navigation,
	PERFORMANCE_SUBJECT_navigationView,
} from '@confluence/performance';
import { GeneralShortcutListener, UNDO_SHORTCUT } from '@confluence/shortcuts';
import { CONTEXT_PATH, CREATE_CONTENT } from '@confluence/named-routes';
import { scrollElementToViewCenter } from '@confluence/dom-helpers';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	getStatusCode,
} from '@confluence/error-boundary';
import { FlagsStateContainer } from '@confluence/flags';
import { usePageState } from '@confluence/page-context';
import { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { PageSegmentLoadStart, PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { START_TOUCH } from '@confluence/navdex';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { markErrorAsHandled } from '@confluence/graphql';
import { getURLBypassingResumeDraftAction } from '@confluence/content-utils';
import type { HoverPageCardProps } from '@confluence/page-card';
import {
	BoldNewPagesSatisfactionSurvey,
	useShowBoldNewPagesSatisfactionSurvey,
	useBoldNewPagesSatisfactionSurveyActions,
} from '@confluence/page-utils/entry-points/BoldNewPagesSatisfactionSurvey';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import type { ItemId, TreeDestinationPosition, TreeSourcePosition } from '@confluence/tree';
import { useOnboardingTrackerAction } from '@confluence/experiment-onboarding-tracker/entry-points/hooks/useOnboardingTrackerAction';
import { ONBOARDING_TRACKER_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useIsFolderContentViewEnabled } from '@confluence/folder-utils/entry-points/useIsFolderContentViewEnabled';
import { checkSSRPartialSuccess, SSR_PARTIAL_COMPONENT } from '@confluence/ssr-utilities';

import type { ContentTreeItem } from './data-transformers';
import type { GraphQLPageStatus } from './pageTreeStatuses';
import { PAGE_TREE_STATUSES } from './pageTreeStatuses';
import type { PageTreeCoordinatorProps } from './PageTreeCoordinator';
import { PageTreeCoordinator } from './PageTreeCoordinator';
import { renderPageTreeItem } from './renderPageTreeItem';
import type { MovePageParams } from './useMovePageHandler';
import { useMovePageHandler } from './useMovePageHandler';
import {
	DragDropEndTrackEvent,
	DragStartUIEvent,
	DragEndUIEvent,
	PageTreeUIAnalytics,
	UndoClickUIEvent,
	UndoShortcutUIEvent,
	PageTreePaginationEvent,
} from './analytics';
import { PageMoveHistory } from './PageMoveHistory';
import { PAGE_TREE_METRIC } from './perf.config';
import { getI18nMessageForKnownError } from './movePublishedPageErrorHelpers';
import { DragPreview } from './DragPreview';
import { useIsDragging } from './use-is-dragging';
import type { loadingErrorLocation } from './usePageTreeState';
import { i18n } from './errorFlagMessages';

const noop = () => {};

export type PageTreeProps = {
	isInDrawer?: boolean;
	onDragDropSuccess?(pageId: string): void;
	onItemClick?(): void;
	isPeekingFromBlogs?: boolean;
	isSuperAdmin?: boolean;
	setIsPageTreeLoading?: (isLoading: boolean) => void;
	setPageTreeError?: (error?: ApolloError) => void;
	isQuickActionsEnabled?: boolean;
	isInlineRenameEnabled?: boolean;
	shouldRenderAfterIcon?: boolean;
	homepageId?: string;
	setContentTreeSize?: (size: number) => void;
	onContentTreeLoadComplete?: () => void;
} & Partial<HoverPageCardProps>;

type PageTreeInnerProps = {
	pageTreeStateUpdatesContainer: PageTreeStateUpdater;
	flags: FlagsStateContainer;
};

const canMoveInSpace = memoizeOne(
	(space) => {
		return !!find(space?.operations ?? [], {
			targetType: 'page',
			operation: 'create',
		});
	},
	([newSpace], [lastSpace]): boolean => {
		return newSpace?.id === lastSpace?.id;
	},
);

const isMovePagePermissionError = ({ graphQLErrors }: ApolloError) => {
	if (!graphQLErrors?.length) {
		return false;
	}

	for (const graphQLError of graphQLErrors) {
		if (
			Array.isArray(graphQLError.path) &&
			graphQLError.path.some((p) => p.includes('movePage')) &&
			getStatusCode(graphQLError) === '403'
		) {
			return true;
		}
	}

	return false;
};

const usePageTreeMoveHistory = ({
	spaceKey,
	flags,
	intl,
	createAnalyticsEvent,
}: {
	spaceKey: string;
	flags: FlagsStateContainer;
	intl: IntlShape;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}) => {
	const previousSpaceKeyRef = useRef(spaceKey);
	const pageMoveHistoryRef = useRef(new PageMoveHistory());

	const undo = useCallback(() => {
		const reverseMovement = pageMoveHistoryRef.current.pop();

		if (reverseMovement) {
			const { undo: undoFn } = reverseMovement;

			if (undoFn) {
				undoFn();
			}
		}
	}, []);

	const onUndoShortcut = useCallback(() => {
		createAnalyticsEvent(UndoShortcutUIEvent()).fire();
		undo();
	}, [createAnalyticsEvent, undo]);

	const showUndoFlag = useCallback(
		(pageTitle: string): void => {
			const id = uuid();
			void flags.showSuccessFlag({
				id,
				title: pageTitle
					? intl.formatMessage(i18n.flagUndoTitle, {
							pageTitle,
						})
					: intl.formatMessage(i18n.flagUndoUntitled),
				actions: [
					{
						content: intl.formatMessage(i18n.flagUndoAction),
						onClick: () => {
							createAnalyticsEvent(UndoClickUIEvent()).fire();
							undo();
							void flags.hideFlag(id);
						},
					},
				],
				isAutoDismiss: true,
			});

			const historyEntry = pageMoveHistoryRef.current.peek();
			if (historyEntry) {
				historyEntry.flagId = id;
			}
		},
		[flags, intl, createAnalyticsEvent, undo],
	);

	const hideLastUndoFlag = useCallback(() => {
		const lastMovement = pageMoveHistoryRef.current.peek();
		if (lastMovement) {
			void flags.hideFlag(lastMovement.flagId);
		}
	}, [flags]);

	const addUndo = useCallback(({ undo }: { undo(): void }) => {
		pageMoveHistoryRef.current.push({ undo });
	}, []);

	if (spaceKey !== previousSpaceKeyRef.current) {
		previousSpaceKeyRef.current = spaceKey;
		hideLastUndoFlag();
		pageMoveHistoryRef.current.reset();
	}

	return {
		onUndoShortcut,
		showUndoFlag,
		hideLastUndoFlag,
		addUndo,
	};
};

const useScrollToItem = ({
	pageTreeStateUpdatesContainer,
}: {
	pageTreeStateUpdatesContainer: PageTreeStateUpdater;
}) => {
	const scrolledItemIdRef = useRef();

	const scrollToItem = useCallback(
		(item: any, el: any) => {
			if (pageTreeStateUpdatesContainer.state.scrollToPageId === item.id && el) {
				scrollElementToViewCenter(el);
				pageTreeStateUpdatesContainer.clearScrollToPageId();
				return;
			}

			if (
				!pageTreeStateUpdatesContainer.state.editingTitle?.contentId &&
				(item.data.isSelected || item.data.isHighlighted)
			) {
				if (pageTreeStateUpdatesContainer.state.scrollToCurrent && item.data.isSelected && el) {
					scrollElementToViewCenter(el);
					pageTreeStateUpdatesContainer.clearScrollTo();
					return;
				}

				if (scrolledItemIdRef.current !== item.id) {
					// Scroll only once to a given page
					scrollElementToViewCenter(el);
				}
				scrolledItemIdRef.current = item.id;
			}
		},
		[pageTreeStateUpdatesContainer],
	);

	return [scrollToItem];
};

const usePageHighlight = ({
	pageTreeStateUpdatesContainer,
}: {
	pageTreeStateUpdatesContainer: PageTreeStateUpdater;
}) => {
	const highlightedPages = useRef<ItemId[]>([]);

	const onPageHighlighted = useCallback(
		(id: ItemId) => {
			highlightedPages.current.push(id);
			// keep a tally of the successfully highlighted pages and notify
			// parent when they've all completed
			const pagesInTreeHighlighted = pageTreeStateUpdatesContainer.state.highlightPagesIds;

			// Checking length rather than deep equality prevents UI weirdness
			// should there happen to be a mismatch. (In that case, deep equality
			// condition would never pass for duration of this component's life, and
			// we would keep re-highlighting the same pages on every tree render.)
			// @TODO FIXME to use Set instead of array.
			if (highlightedPages.current.length === pagesInTreeHighlighted.length) {
				pageTreeStateUpdatesContainer.clearHighlightedPages();
				highlightedPages.current = [];
			}
		},
		[pageTreeStateUpdatesContainer],
	);

	return [onPageHighlighted];
};

/**
 * This component is what makes the generic `PageTreeController` into a fully functional
 * navigational Page Tree that we all love. It is the one to actually handle things like:
 *  - analytics, performance, and experience tracking events
 *  - backend mutations after drag-n-drop
 *  - orchestrating "undo" for the d-n-d
 *  - showing flags of various kinds (successes/failures). Although triggers for these might originate in other components.
 */
const PageTreeInner = ({
	isInDrawer = false,
	onDragDropSuccess = noop,
	onItemClick,
	isPeekingFromBlogs,
	isSuperAdmin = false,
	pageTreeStateUpdatesContainer,
	flags,
	setIsPageTreeLoading,
	setPageTreeError = noop,
	isHoverPageCardOptedIn = false,
	closeAllHoverPageCards = noop,
	isQuickActionsEnabled = false,
	isInlineRenameEnabled = false,
	onEnterHoverTarget = noop,
	onLeaveHoverTarget = noop,
	onBlurHoverTarget = noop,
	shouldRenderAfterIcon,
	homepageId,
	setContentTreeSize = noop,
	onContentTreeLoadComplete = noop,
}: PageTreeProps & PageTreeInnerProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const showBoldNewPagesSatisfactionSurvey = useShowBoldNewPagesSatisfactionSurvey();
	const { incrementUnreadPagesVisited } = useBoldNewPagesSatisfactionSurveyActions();
	const { isFolderContentViewEnabled } = useIsFolderContentViewEnabled();

	const { push } = useContext(RoutesContext);
	const [{ spaceKey: stateSpaceKey, contentId, contentIdLoading, routeName }] = usePageState();
	// Many analytics callbacks are sending the current viewing page. The problem is these callbacks are passed to every page tree item.
	// If we use contentId directly the callback functions are going to be regenerated and making all page tree items re-render.
	const contentIdForAnalyticsRef = useRef<string | null | undefined>(contentId);

	// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
	const spaceKey: string = stateSpaceKey;
	const { loadEditor } = useEditPageLoadingActions();

	const [openQuickActionsId, setOpenQuickActionsId] = useState<string | null>(null);

	const [focusedQuickActionsId, setFocusedQuickActionsId] = useState<string | null>(null);

	const { setIsDraggingItem, getIsDraggingItem } = useIsDragging();

	const isOnEditRoute = useIsEditorPage();
	const isOnEditRouteRef = useRef<boolean>();

	useEffect(() => {
		isOnEditRouteRef.current = isOnEditRoute;
		contentIdForAnalyticsRef.current = contentId;
	}, [isOnEditRoute, contentId]);

	const renderResourcedEmoji = useRef<boolean>(false);

	const { onUndoShortcut, showUndoFlag, hideLastUndoFlag, addUndo } = usePageTreeMoveHistory({
		flags,
		intl,
		createAnalyticsEvent,
		spaceKey,
	});

	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'page-tree-page-tree-item',
	});

	const visitPage = useCallback(
		(event: any, page: any, spaceId?: string | null, getContentTreeSize?: () => number) => {
			const contentTreeSize = getContentTreeSize?.();
			closeAllHoverPageCards?.();
			createAnalyticsEvent(
				PageTreeUIAnalytics(
					'clicked',
					spaceId, //spaceId was already passing Undefined
					isInDrawer,
					page.data?.status,
					contentIdForAnalyticsRef.current,
					page.data?.type,
					START_TOUCH,
					isOnEditRouteRef.current,
					page.data?.isUnread,
					contentTreeSize,
				),
			).fire();

			if (page.data?.isUnread) {
				void incrementUnreadPagesVisited();
			}

			// metaKey = ⌘ in macOS or middle scroll mouse button
			// Use default browser behaviour when any of these key was pressed
			if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey || event.button === 1) {
				return; // let the link's href handle the navigation
			}

			const url = `${CONTEXT_PATH}${page.data?.webui}`;
			const bypassURL = getURLBypassingResumeDraftAction({
				url,
				spaceKey,
				contentType: page?.data?.type,
				editorVersion: page?.data?.editorVersion,
				contentId: page.id,
			});

			const isDraft = page.data?.status === 'DRAFT';
			if (isDraft) {
				event.preventDefault();
				event.stopPropagation();
				loadEditor({
					contentId: page.id,
					spaceKey,
					redirectUrl: bypassURL,

					isFabricSupported: page?.data?.editorVersion === 'v2',
					isEmbeddedContent: false,
				});
			} else {
				event.preventDefault();
				push(url);
			}
			if (onItemClick) {
				onItemClick();
			}
		},
		[
			push,
			onItemClick,
			closeAllHoverPageCards,
			createAnalyticsEvent,
			isInDrawer,
			spaceKey,
			loadEditor,
			incrementUnreadPagesVisited,
			isOnEditRouteRef,
		],
	);

	const handleClick = useCallback(
		(
			event: MouseEvent,
			page: ContentTreeItem,
			onExpand: ((itemId: string) => void) | undefined,
			onCollapse: ((itemId: string) => void) | undefined,
			...args
		) => {
			if (!isFolderContentViewEnabled && page.data.type === 'folder') {
				page.isExpanded ? onCollapse?.(page.id) : onExpand?.(page.id);
				return;
			}

			// Check for storage enforcement before sending users through to draft pages
			const isDraft = page.data?.status === 'DRAFT';
			(isDraft ? enforceStorageLimit(visitPage) : visitPage)(event, page, ...args);
		},
		[visitPage, enforceStorageLimit, isFolderContentViewEnabled],
	);

	const draftText = intl.formatMessage(i18n.untitledDraftText);

	const dragPreview = useCallback((item: any) => <DragPreview item={item} />, []);

	const statusesRef = useRef<GraphQLPageStatus[]>(PAGE_TREE_STATUSES);

	const [scrollToItem] = useScrollToItem({ pageTreeStateUpdatesContainer });

	const [onPageHighlighted] = usePageHighlight({
		pageTreeStateUpdatesContainer,
	});

	const { movePage } = useMovePageHandler();
	const { markOnboardingTrackerItemCompleted } = useOnboardingTrackerAction();
	const [dndError, setDnDError] = useState<ApolloError | null>(null);
	const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
	const [initialPageTreeSize, setInitialPageTreeSize] = useState<number>(0);
	const pageTreeWasSSRRendered = checkSSRPartialSuccess(SSR_PARTIAL_COMPONENT.PageTree);

	if (!renderResourcedEmoji.current && pageTreeWasSSRRendered) {
		renderResourcedEmoji.current = true;
	}

	const managedOnEnterHoverTarget = useCallback(
		(...args: Parameters<typeof onEnterHoverTarget>) => {
			// while a drag is occurring, we don't also items to have their hover action triggered
			if (!getIsDraggingItem()) {
				onEnterHoverTarget(...args);
			}
		},
		[onEnterHoverTarget, getIsDraggingItem],
	);

	const editingTitle = pageTreeStateUpdatesContainer.state.editingTitle;
	const isEditingTitleId = editingTitle?.contentId;
	const setIsEditingTitleId = useCallback(
		(pageId) => {
			pageTreeStateUpdatesContainer.setIsEditingTitleId(pageId);
		},
		[pageTreeStateUpdatesContainer],
	);

	const renderItem = useCallback<PageTreeCoordinatorProps['renderItem']>(
		(params) => {
			const {
				spaceHomePageId,
				updateSinglePage,
				spaceId,
				getContentTreeSize,
				...renderItemParams
			} = params;
			return renderPageTreeItem(renderItemParams, {
				draftText,
				isSuperAdmin,
				spaceHomePageId,
				onClick: handleClick,
				onExpand: renderItemParams.onExpand,
				onCollapse: renderItemParams.onCollapse,
				spaceKey,
				spaceId,
				scrollToItem,
				onPageHighlighted,
				isHoverPageCardOptedIn,
				isQuickActionsEnabled,
				isInlineRenameEnabled,
				closeAllHoverPageCards,
				openQuickActionsId,
				setOpenQuickActionsId,
				focusedQuickActionsId,
				setFocusedQuickActionsId,
				editingTitle,
				setIsEditingTitleId,
				onEnterHoverTarget: managedOnEnterHoverTarget,
				onLeaveHoverTarget,
				onBlurHoverTarget,
				updateSinglePage,
				pageTreeWasSSRRendered,
				renderResourcedEmoji: renderResourcedEmoji.current,
				shouldRenderAfterIcon,
				hideDraftHrefs: shouldBlockCreate,
				getContentTreeSize,
			});
		},
		[
			draftText,
			isSuperAdmin,
			handleClick,
			spaceKey,
			scrollToItem,
			onPageHighlighted,
			isHoverPageCardOptedIn,
			isQuickActionsEnabled,
			isInlineRenameEnabled,
			closeAllHoverPageCards,
			openQuickActionsId,
			focusedQuickActionsId,
			editingTitle,
			setIsEditingTitleId,
			managedOnEnterHoverTarget,
			onLeaveHoverTarget,
			onBlurHoverTarget,
			pageTreeWasSSRRendered,
			shouldRenderAfterIcon,
			renderResourcedEmoji,
			shouldBlockCreate,
		],
	);

	useEffect(() => {
		if (pageTreeWasSSRRendered) {
			//set FMP now if SSR rendered and we didn't skip pageTree rendering
			PAGE_TREE_METRIC.markFMP(getMark('CFP-63.ssr-ttr'));
		}
	}, [pageTreeWasSSRRendered]);

	const onLoadError = useCallback(
		(
			apolloError: ApolloError,
			options?: { isExpected?: boolean; location?: loadingErrorLocation },
		) => {
			void flags.showErrorFlag({
				title: intl.formatMessage(i18n.flagErrorTitle),
				description: location
					? intl.formatMessage(i18n.paginateErrorDescription)
					: intl.formatMessage(i18n.flagErrorDescription),
				isAutoDismiss: true,
			});

			if (options?.isExpected) {
				// This is a temporary measure as we gradually improve PageTree error handling.
				// Normally expected errors should not render a generic error component and
				// instead should provide an error-specific experience.
				experienceTracker.succeed({
					name: PAGE_TREE_EXPERIENCE,
				});
			} else {
				setPageTreeError(apolloError);
				experienceTracker.stopOnError({
					name: PAGE_TREE_EXPERIENCE,
					attributes: { location: options?.location || 'main' },
					error: apolloError,
				});
			}
		},
		[flags, intl, experienceTracker, setPageTreeError],
	);

	const onPageMove = useCallback(
		(
			moveParams: MovePageParams,
			{
				contentStatus,
				movedPageTitle,
				spaceId,
				source,
				destination,
				revertMove,
			}: {
				contentStatus: string | null;
				movedPageTitle: string;
				spaceId?: string | null;
				source: TreeSourcePosition;
				destination: TreeDestinationPosition;
				revertMove(): MovePageParams;
			},
		) => {
			createAnalyticsEvent(DragEndUIEvent(true)).fire();

			const handleDragDropSuccess = (spaceId, pageId, sourceParentId, destinationParentId) => {
				createAnalyticsEvent(
					DragDropEndTrackEvent({
						source: 'PageTree',
						contentStatus,
						pageId,
						previousParentPageId: sourceParentId,
						newParentPageId: destinationParentId,
						previousSpaceId: spaceId,
						newSpaceId: spaceId,
						isOnEditRoute: isOnEditRouteRef.current,
					}),
				).fire();
				markOnboardingTrackerItemCompleted(ONBOARDING_TRACKER_STATE_KEYS.HAS_EXPLORED_SPACE);
				experienceTracker.succeed({ name: PAGE_TREE_DND_EXPERIENCE });
				onDragDropSuccess(pageId);
			};

			const handleDndError = (apolloError: ApolloError) => {
				// undo the move in state so that the tree reflects that the move did not succeed
				revertMove();

				if (!apolloError) {
					return;
				}

				const i18nMessage = getI18nMessageForKnownError(apolloError);
				if (i18nMessage !== undefined) {
					markErrorAsHandled(apolloError);

					void flags.showErrorFlag({
						title: i18nMessage.title.defaultMessage,
						description: i18nMessage.description.defaultMessage,
						isAutoDismiss: true,
					});

					experienceTracker.succeed({
						name: PAGE_TREE_DND_EXPERIENCE,
						attributes: {
							expectedErrorDescription: i18nMessage.description.defaultMessage,
						},
					});
				} else if (isMovePagePermissionError(apolloError)) {
					void flags.showErrorFlag({
						title: intl.formatMessage(i18n.flagMovePagePermissionErrorTitle),
						description: intl.formatMessage(i18n.flagMovePagePermissionErrorDescription),
						isAutoDismiss: true,
					});

					experienceTracker.abort({
						name: PAGE_TREE_DND_EXPERIENCE,
						reason: 'User lacks move permissions',
					});
				} else {
					void flags.showErrorFlag({
						title: intl.formatMessage(i18n.flagErrorTitle),
						description: intl.formatMessage(i18n.flagErrorDescription),
						isAutoDismiss: true,
					});

					experienceTracker.stopOnError({
						name: PAGE_TREE_DND_EXPERIENCE,
						error: apolloError,
					});
				}

				setDnDError(apolloError);
			};

			const reverseMove = () => {
				const reverseMoveParams = revertMove();
				movePage(reverseMoveParams)
					.then(() => {
						handleDragDropSuccess(
							spaceId,
							moveParams.pageId,
							// If the move is reversed, the source will be the original destination
							// and the destination will be the original source.
							destination.parentId,
							source.parentId,
						);
					})
					.catch(handleDndError);
			};

			addUndo({ undo: reverseMove });

			movePage(moveParams)
				.then(() => {
					handleDragDropSuccess(spaceId, moveParams.pageId, source.parentId, destination.parentId);

					showUndoFlag(movedPageTitle);
				})
				.catch(handleDndError);
		},
		[
			experienceTracker,
			flags,
			intl,
			addUndo,
			movePage,
			showUndoFlag,
			createAnalyticsEvent,
			onDragDropSuccess,
			markOnboardingTrackerItemCompleted,
			isOnEditRouteRef,
		],
	);

	const forceFetchedCallback = useCallback(
		(state) => {
			const lastForceFetchId = pageTreeStateUpdatesContainer.state.forceFetchPageId;
			pageTreeStateUpdatesContainer.clearForceFetch();

			const newContentId = editingTitle?.contentId;

			// For paginated page trees, force fetch id of the newly created content if the content does not
			// have a create content route. This will enable properly scrolling to the new item
			if (
				(state.isTreeTruncatedFollowing || state.isTreeTruncatedPrevious) &&
				editingTitle?.isNewContent &&
				lastForceFetchId !== newContentId
			) {
				pageTreeStateUpdatesContainer.forceFetchWithInlineRename(newContentId, newContentId);
			}
		},
		[pageTreeStateUpdatesContainer, editingTitle],
	);

	const onMoveInvalid = useCallback(
		({
			reason,
		}: {
			reason: 'TREE_TOO_LARGE' | 'INVALID_MOVE_LOCATION' | 'INVALID_MOVE_LOCATION_DRAFT';
		}) => {
			createAnalyticsEvent(DragEndUIEvent(false)).fire();

			const showLargePageTreeFlag = () => {
				void flags.showWarningFlag({
					title: intl.formatMessage(i18n.flagLargePageTreeTitle),
					description: intl.formatMessage(i18n.flagLargePageTreeDescription),
					actions: [
						{
							content: intl.formatMessage(i18n.flagLargePageTreeSettingsAction),
							onClick: () => {
								push(`/wiki/pages/reorderpages.action?key=${spaceKey}`);
							},
						},
						{
							content: intl.formatMessage(i18n.flagLargePageTreeRefreshAction),
							onClick: () => window.location.reload(),
						},
					],
					isAutoDismiss: true,
				});
			};

			const showInvalidMoveDraftFlag = () => {
				void flags.showErrorFlag({
					title: intl.formatMessage(i18n.flagInvalidMoveDraftTitle),
					description: intl.formatMessage(i18n.flagInvalidMoveDraftDescription),
					isAutoDismiss: true,
				});
			};

			switch (reason) {
				case 'TREE_TOO_LARGE': {
					showLargePageTreeFlag();
					experienceTracker.abort({
						name: PAGE_TREE_DND_EXPERIENCE,
						reason: 'Large page tree limit exceeded',
					});
					break;
				}
				case 'INVALID_MOVE_LOCATION': {
					experienceTracker.abort({
						name: PAGE_TREE_DND_EXPERIENCE,
						reason: 'Invalid move location',
					});
					break;
				}
				case 'INVALID_MOVE_LOCATION_DRAFT': {
					showInvalidMoveDraftFlag();
					experienceTracker.abort({
						name: PAGE_TREE_DND_EXPERIENCE,
						reason: 'Invalid move location onto draft',
					});
					break;
				}
			}
		},
		[createAnalyticsEvent, experienceTracker, flags, intl, push, spaceKey],
	);

	const onDragStart = useCallback(() => {
		closeAllHoverPageCards();
		setOpenQuickActionsId(null);
		createAnalyticsEvent(DragStartUIEvent()).fire();
		experienceTracker.start({
			name: PAGE_TREE_DND_EXPERIENCE,
		});
		hideLastUndoFlag();
	}, [createAnalyticsEvent, experienceTracker, hideLastUndoFlag, closeAllHoverPageCards]);

	const expandAnalyticsCallback = useCallback(
		(page: ContentTreeItem, { spaceId }: { spaceId?: string | null }) => {
			createAnalyticsEvent(
				PageTreeUIAnalytics(
					'expanded',
					spaceId,
					isInDrawer,
					page.data?.status,
					contentIdForAnalyticsRef.current,
					page.data?.type,
					START_TOUCH,
					isOnEditRouteRef.current,
				),
			).fire();
		},
		[createAnalyticsEvent, isInDrawer, isOnEditRouteRef],
	);

	const collapseAnalyticsCallback = useCallback(
		(page: ContentTreeItem, { spaceId }: { spaceId?: string | null }) => {
			createAnalyticsEvent(
				PageTreeUIAnalytics(
					'collapsed',
					spaceId,
					isInDrawer,
					page.data?.status,
					contentIdForAnalyticsRef.current,
					page.data?.type,
					START_TOUCH,
					isOnEditRouteRef.current,
				),
			).fire();
		},
		[createAnalyticsEvent, isInDrawer, isOnEditRouteRef],
	);

	const isDraggable = useCallback(
		(item: any, { space }: any) => {
			if (item.id === isEditingTitleId) {
				return false;
			}
			return canMoveInSpace(space);
		},
		[isEditingTitleId],
	);

	const onLoadMore = useCallback(
		(pagesCount = undefined, spaceId = undefined, direction = undefined) => {
			createAnalyticsEvent(
				PageTreePaginationEvent(spaceId, pagesCount, 'containerNavigation', direction),
			).fire();
		},
		[createAnalyticsEvent],
	);

	/**
	 * onInitialLoadComplete is called after the first initial load of the page tree's data. This includes refreshing the page.
	 * It does not include returning from blog peek, transitioning from blog BACK to page tree, transitioning amongst pages in page tree, etc.
	 */
	const onInitialLoadComplete = useCallback(
		(pagetreeSize?: number) => {
			perfMarkEnd({
				subject: PERFORMANCE_SUBJECT_navigation,
				subjectId: 'NavigationLoading',
				details: {
					navigationView: 'container',
					view: 'space',
					navVersion: 3,
					spaceKey,
				},
			});
			perfMarkEnd({
				subject: PERFORMANCE_SUBJECT_navigationView,
				subjectId: 'SpaceViewLoading',
				details: {
					navigationView: 'container',
					view: 'space',
					navVersion: 3,
					spaceKey,
				},
			});
			experienceTracker.succeed({ name: PAGE_TREE_EXPERIENCE });
			onContentTreeLoadComplete(); // show blog tree spotlight only after page tree finishes loading to prevent misplaced spotlight
			if (pagetreeSize && pagetreeSize > 2) {
				setInitialPageTreeSize(pagetreeSize - 2); //subtract 2 for VIRTUAL_LOCAL_ROOT_ID and VIRTUAL_ROOT_ID
			}
			setInitialLoadComplete(true);
		},
		[experienceTracker, spaceKey, onContentTreeLoadComplete],
	);

	const currentPageId =
		pageTreeStateUpdatesContainer.state.forceFetchPageId ||
		(isPeekingFromBlogs ? homepageId : contentId);

	const isCreateContentRoute = routeName === CREATE_CONTENT.name;

	// Note: content id can be null even when contentIdLoading = false
	// Because a space may not have homepage
	const showPageTree = Boolean(contentId) || !contentIdLoading;
	if (!showPageTree) {
		return null;
	}

	return (
		<Fragment>
			<PageSegmentLoadStart metric={PAGE_TREE_METRIC} />
			<ExperienceStart key={`experience-${spaceKey}`} name={PAGE_TREE_EXPERIENCE} id={spaceKey} />
			{dndError && <ErrorDisplay error={dndError} />}
			<GeneralShortcutListener accelerator={UNDO_SHORTCUT} listener={onUndoShortcut} />
			{showBoldNewPagesSatisfactionSurvey && <BoldNewPagesSatisfactionSurvey />}
			<PageTreeCoordinator
				// CONFCLOUD-69158 The key here is forcing page tree to re-mount when switching from space to space
				// This preventing issue when transition from a space that has homepage to a space without
				key={spaceKey}
				statuses={statusesRef.current}
				isPeekingFromBlogs={isPeekingFromBlogs}
				onPageMove={onPageMove}
				onDragStart={onDragStart}
				onMoveInvalid={onMoveInvalid}
				onLoadError={onLoadError}
				onExpand={expandAnalyticsCallback}
				onCollapse={collapseAnalyticsCallback}
				onLoadMore={onLoadMore}
				onInitialLoadComplete={onInitialLoadComplete}
				currentPageId={currentPageId}
				highlightPages={pageTreeStateUpdatesContainer.state.highlightPagesIds}
				forceFetchCurrentPage={Boolean(pageTreeStateUpdatesContainer.state.forceFetchPageId)}
				forceFetchedCallback={forceFetchedCallback}
				spaceKey={spaceKey}
				renderItem={renderItem}
				dragPreview={dragPreview}
				isDraggable={isDraggable}
				setIsPageTreeLoading={setIsPageTreeLoading}
				isSuperAdmin={isSuperAdmin}
				getIsDraggingItem={getIsDraggingItem}
				setIsDraggingItem={setIsDraggingItem}
				setOpenQuickActionsId={setOpenQuickActionsId}
				renderResourcedEmoji={renderResourcedEmoji.current}
				setContentTreeSize={setContentTreeSize}
				pageTreeStateUpdatesContainer={pageTreeStateUpdatesContainer}
				isCreateContentRoute={isCreateContentRoute}
			/>
			{(initialLoadComplete || process.env.REACT_SSR) && (
				<Fragment>
					<PageSegmentLoadEnd
						metric={PAGE_TREE_METRIC}
						customData={{
							initialPageTreeSize,
							skipTreeRenderSSR: !pageTreeWasSSRRendered, //tracks if Best effort failed & if tree was over 200 pages
						}}
						stopTime={pageTreeWasSSRRendered ? getMark('CFP-63.ssr-ttr') : undefined}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};
export const PageTree: FC<PageTreeProps> = memo((props) => {
	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<Subscribe to={[FlagsStateContainer]}>
				{(flags: FlagsStateContainer) => (
					<Subscribe to={[PageTreeStateUpdater]}>
						{(pageTreeStateUpdatesContainer: PageTreeStateUpdater) => (
							<PageTreeInner
								{...props}
								pageTreeStateUpdatesContainer={pageTreeStateUpdatesContainer}
								flags={flags}
							/>
						)}
					</Subscribe>
				)}
			</Subscribe>
		</ErrorBoundary>
	);
});

PageTree.displayName = 'PageTree';
